import React, {useState, useEffect} from "react";
import TripReportWizard from "./TripReportWizard";
import history from "../../variables/history";
import {create, get, update} from "../../variables/backend";
import {notify} from "../../variables/theme";

export default function ({tripId, report, wrapped, onBack}) {
    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();
    const [errorType, setErrorTypeInternal] = useState();
    const [severity, setSeverityInternal] = useState();
    const [note, setNote] = useState();

    const setSeverity = (s) => {
        if (!severity || severity.id !== s?.id) {
            setSeverityInternal(s);
        }
    }

    const setErrorType = (e) => {
        if (!errorType || errorType.id !== e?.id) {
            setErrorTypeInternal(e);
        }
    }

    const [errorTypes, setErrorTypes] = useState();
    const [severities, setSeverities] = useState();
    const [trip, setTrip] = useState();

    const submit = () => {
        let f = create;

        if (report)
            f = update;

        tripId && f("/trips/" + tripId + "/report/" + (report?.id || ""), {
            location: {
                latitude: latitude,
                longitude: longitude
            },
            errorType: errorType?.id,
            severity: severity?.id,
            note: note,
            draft: false
        }).then(r => {
            back()
        }).catch(err => {
            notify({
                place: "tc",
                type: "danger",
                autoDismiss: 3,
                message: (
                    <div>
                        <div className="h5 m-2 mb-3 text-danger">
                            Fahler beim Speichern
                        </div>
                        <div>
                            {err.response.data.message}
                        </div>
                    </div>
                )
            })
            console.log(err.response)
        });
    }

    const back = onBack || (() => history.goBack());

    useEffect(() => {
        get("/fixture/", {include: "severities,errorTypes"}).then(r => {
            setSeverities(r.severities);
            setErrorTypes(r.errorTypes);
        })
    }, []);

    useEffect(() => {
        if (report) {
            setNote(report.notes?.scout);
            setLatitude(report.location.lat);
            setLongitude(report.location.lon);
            if (errorTypes) {
                setErrorType(errorTypes.find(e => e.id === report.error?.id));
            }
            if (severities) {
                setSeverity(severities.find(s => s.id === report.severity?.id));
            }
        }
    }, [report, errorTypes, severities]);

    useEffect(() => {
        if (report) {
            setTrip(report.trip);
        } else {
            tripId && get("/trips/" + tripId).then(r => setTrip(r.trip));
        }
    }, [tripId])

    return <>
        {!wrapped && <div className="content">
            <TripReportWizard errorTypes={errorTypes} severities={severities} errorType={errorType}
                              setErrorType={setErrorType} note={note} setNote={setNote} latitude={latitude}
                              longitude={longitude} setLatitude={setLatitude} setLongitude={setLongitude}
                              severity={severity} setSeverity={setSeverity} onSubmit={submit} onBack={back}
                              trip={trip} reportId={report?.id} />
        </div>}
        {wrapped && <TripReportWizard errorTypes={errorTypes} severities={severities} errorType={errorType}
                                      setErrorType={setErrorType} note={note} setNote={setNote} latitude={latitude}
                                      longitude={longitude} setLatitude={setLatitude} setLongitude={setLongitude}
                                      severity={severity} setSeverity={setSeverity} onSubmit={submit} onBack={back}
                                      trip={trip} reportId={report?.id}/>}
    </>
};
