import React, {useEffect, useState} from "react";
import {Button, Col, NavLink, Row, Table} from "reactstrap";
import {action, formatDateToString} from "../../variables/toolkit";
import history from "../../variables/history";
import {get} from "../../variables/backend";
import strings, {TRIP_STATES} from "../../assets/lang/localization";

function TripsTable({list, title}) {
    return <>
        <Row>
            <Col>
                <h1>{title}</h1>
            </Col>
        </Row>
        <Row>
            <Col>
                {list && list.length > 0 && <Table>
                    <thead>
                    <tr>
                        <td>Datum</td>
                        <td>Route</td>
                        <td className="d-none d-md-block">Meldungen</td>
                        <td>Status</td>
                        <td/>
                    </tr>
                    </thead>
                    <tbody>
                    {list.map(trip => <tr key={trip.id}>
                        <td>{formatDateToString(trip.date, true)}</td>
                        <td>{trip.route.signage}&nbsp;{trip.route.name}</td>
                        <td className="d-none d-md-table-cell">{trip.reports.length}&nbsp;Meldungen</td>
                        <td>{strings().getString(TRIP_STATES)[trip.state]}</td>
                        <td align={"right"} width="24rem">
                            <Button outline color="default" onClick={e => {
                                e.preventDefault();
                                history.push("/trip/" + trip.id);
                            }}>Details</Button>
                        </td>
                    </tr>)}
                    </tbody>
                </Table> || <span>Keine {title}</span>}
            </Col>
        </Row></>
}

const Overview = (props) => {
    const [trips, setTrips] = useState();
    const [openTrips, setOpenTrips] = useState();

    useEffect(() => {
        get("/trips/").then(r => {
            const otherTrips = [];
            const openTrips = [];

            console.log(r)
            r.forEach(t => t.state === 0 ? openTrips.push(t) : otherTrips.push(t));

            setTrips(otherTrips);
            setOpenTrips(openTrips)
        });
    }, []);

    return (<div className="content">
        {/*<TripsTable list={openTrips} title={"Offene Kontrollfahrten"} />*/}
        {/*<Row>*/}
        {/*    <Col>*/}
        {/*        <Button className="pull-right" outline color="success" onClick={e => action(e, () => history.push("/trip/create"))}>Neue Kontrollfahrt</Button>*/}
        {/*    </Col>*/}
        {/*</Row>*/}
        <TripsTable list={trips} title={"Abgeschlossene Kontrollfahrten"} />
    </div>);
};

export default Overview;
