import React from "react";
import ReactDOM from "react-dom";
import {Route, Router, Switch} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.2.0";
import "assets/demo/demo.css";

import {RoutingWrapper} from "layouts/Admin.js";
import history from "./variables/history";
import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {ThemeSwitcher} from "./variables/theme";
import {Localization} from "./assets/lang/localization";

const toDoubleDigit = (val) => {
    return val < 10 && val > 0 ? "0" + val : val;
}

// eslint-disable-next-line
Array.prototype.isOnlyUndefined = function () {
    let defined = false;
    for (let i = 0; i < this.length; i++) {
        if (this[i]) {
            defined = true;
        }
    }
    return !defined;
}

// eslint-disable-next-line
Date.prototype.addDays = function (days) {
    this.setDate(this.getDate() + days);
    return this;
}

// eslint-disable-next-line
Date.nowMinutesStripped = (precision = 1) => {
    let newDate = new Date();
    newDate.setMilliseconds(0);
    newDate.setSeconds(0);

    const multiplikator = Math.floor(newDate.getMinutes() / precision);
    newDate.setMinutes((newDate.getMinutes() % precision > precision / 2 ? multiplikator + 1 : multiplikator) * precision);

    return newDate;
}

// eslint-disable-next-line
Date.prototype.addDaysToNew = function (days) {
    let newDate = new Date();
    newDate.setTime(this.getTime());
    newDate.setDate(newDate.getDate() + days);
    return newDate;
}

// eslint-disable-next-line
Date.prototype.toStringDate = function () {
    return this.getFullYear() + toDoubleDigit(this.getMonth() + 1) + toDoubleDigit(this.getDate());
}

library.add(fas);

ReactDOM.render(
    <Router basename="/" history={history}>
        <Localization />
        <ThemeSwitcher/>
        <RoutingWrapper />
    </Router>,
    document.getElementById("root")
);
