import React, {useState} from "react";

let theme, setTheme;

function ThemeSwitcher() {
    [theme, setTheme] = useState("white");
    return <></>
}

const notificationHandle = React.createRef();

const notify = (options) => {
    notificationHandle.current.notificationAlert(options);
}

export {
    ThemeSwitcher ,theme, setTheme, notificationHandle, notify
};
