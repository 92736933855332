import React from "react";
import {Button, Card, CardFooter, CardHeader, Col, Row, Table} from "reactstrap";
import strings, {REPORT_STATES} from "../../assets/lang/localization";
import {action} from "../../variables/toolkit";
import history from "../../variables/history";

export default function (props) {
    return <div className="content">
        <Row className="mb-3">
            <Col>
                <h1 className="mb-0 text-primary">Berichte</h1><br/>
                <h2 className="mt-0 text-secondary">Übersicht</h2>
            </Col>
        </Row>
        <Row>
            <Col>
                <Card>
                    <CardHeader>
                        <span>Statusmeldungen</span>
                    </CardHeader>
                    <CardFooter>
                        <Button className="pull-right" outline color="success"
                                onClick={e => action(e, () => history.push("/reporting/trTable"))}>Öffnen</Button>
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    </div>
}
