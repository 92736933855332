import React, {useEffect, useState} from "react";
import {
    Button, ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col, Collapse, Form,
    Input,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row,
    Table
} from "reactstrap";
import {action, doNothingAction, toDoubleDigit} from "../../variables/toolkit";
import history from "../../variables/history";
import {API_URL, create, get, update} from "../../variables/backend";
import strings, {REPORT_STATES, TRIP_STATES} from "../../assets/lang/localization";
import InputSelect from "../../components/Forms/InputSelect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CreateTripReport from "./CreateTripReport";

const msToDate = (ms) => {
    const date = new Date(ms);
    return date.getFullYear() + "-" + toDoubleDigit(date.getMonth() + 1) + "-" + toDoubleDigit(date.getDate());
}

function ReportDetailsModal({report, onClose}) {
    return <Modal isOpen={true} toggle={onClose} size="lg">
        <ModalHeader>Meldungsdetails</ModalHeader>
        <ModalBody>
            {report.draft &&
                <span className="mb-4 font-italic">Diese Meldung ist ein Entwurf und wird nach Abschluss der Kontrollfahrt wieder gelöscht!</span>}
            {<>
                <div>
                    <span className="text-muted">Koordinaten</span><br/>
                    <span><big>{report.location.lat + " / " + report.location.lon}</big></span>
                </div>
                <div className="mt-2">
                    <span className="text-muted">Kategorie</span><br/>
                    <span><big>{report.error?.name || "Keine Kategorie"}</big></span>
                </div>
                {report.severity && <div className="mt-2">
                    <span className="text-muted">Dringlichkeit</span><br/>
                    <span><big>{report.severity.name}</big></span>
                </div>}
                {report.notes?.scout && <div className="mt-2">
                    <span className="text-muted">Notizen</span><br/>
                    <span><big>{report.notes?.scout}</big></span>
                </div>}
                {report?.imageIds?.map(id => <img alt="Bild" key={id} className="my-3"
                                                  src={API_URL("/trips/" + report.trip.id + "/reports/" + report.id + "/image/" + id)}/>)}
            </>}
        </ModalBody>
        <ModalFooter>
            <Button className="w-100" outline color="default" onClick={e => action(e, () => onClose())}><span
                className="mx-auto">Schließen</span></Button>
        </ModalFooter>
    </Modal>;
}

function EditTripView({publisher, trip, back}) {
    const [date, setDate] = useState(Date.now());
    const [route, setRoute] = useState();
    const [section, setSection] = useState();
    const [state, setState] = useState();
    const [notes, setNotes] = useState();
    const [error, setError] = useState();

    const [routes, setRoutes] = useState();
    const [sections, setSections] = useState();

    useEffect(() => {
        get("/fixture/", {include: "routes"}).then(r => setRoutes(r.routes));
    }, [])

    useEffect(() => {
        if (trip) {
            setDate(trip.date);
            setRoute(routes?.find(r => r.id === trip.route?.id));
            setSection(sections?.find(s => s.id === trip.section?.id));
            setState(strings().getString(TRIP_STATES)[trip.state]);
            setNotes(trip.notes);
        }
    }, [trip, routes, sections]);

    useEffect(() => {
        if (route) {
            setSections(route.sections);
        }
    }, [route])

    const save = (e) => {
        doNothingAction(e);
        publisher({
            date: date,
            route: route ? route.id : null,
            section: section ? section.id : null,
            state: strings().getString(TRIP_STATES).indexOf(state),
            notes: notes
        }, error => setError(error.message));
    }

    return <>
        <Row className="mb-3">
            <Col>
                <h1 className="mb-0 pb-0">{trip ? "Kontrollfahrt bearbeiten" : "Neue Kontrollfahrt"}</h1><br/>
                {trip && <h4 className="mt-0 pt-0">{new Date(trip.date).toLocaleDateString()}</h4>}
            </Col>
        </Row>
        <Row>
            <Col xs={12}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col xs={12} lg={4}>
                                <div className="mt-2 mb-3"><span className="h4">Allgemeines</span></div>
                                <Table borderless>
                                    <tbody>
                                    <tr>
                                        <td width={1} className="text-nowrap" align={"right"}>Datum:</td>
                                        <td><Input type="date" value={msToDate(date)} onChange={(e) => {
                                            if (e.target.value) {
                                                setDate(e.target.valueAsNumber);
                                            } else {
                                                setDate(null);
                                            }
                                        }}/></td>
                                    </tr>
                                    <tr>
                                        <td width={1} className="text-nowrap" align={"right"}>Strecke:</td>
                                        <td>
                                            {route &&
                                                <InputSelect className="d-flex" id={"route_selection"} options={routes}
                                                             onChange={setRoute} defaultValue={route}
                                                             converter={r => (r.signage ? r.signage + " - " : "") + (r.shortName || r.name)}/> ||
                                                <InputSelect className="d-flex" id={"route_selection"} options={routes}
                                                             onChange={setRoute} defaultValue={null}
                                                             converter={r => (r.signage ? r.signage + " - " : "") + (r.shortName || r.name)}/>}
                                        </td>
                                    </tr>
                                    {sections && sections.length > 0 && <tr>
                                        <td width={1} className="text-nowrap" align={"right"}>Abschnitt:</td>
                                        <td>
                                            <InputSelect className="d-flex" id={"route_selection"} options={sections}
                                                         onChange={setSection} defaultValue={section}
                                                         converter={r => (r.shortName || r.name)}/>
                                        </td>
                                    </tr>}
                                    <tr>
                                        <td width={1} className="text-nowrap" align={"right"}>Status:</td>
                                        <td>
                                            {state && <InputSelect className="d-flex" id={"state_selection"}
                                                                   defaultValue={state}
                                                                   options={strings().getString(TRIP_STATES)}
                                                                   onChange={setState}
                                                                   converter={r => r}/> ||
                                                <InputSelect className="d-flex" id={"state_selection"}
                                                             defaultValue={null}
                                                             options={strings().getString(TRIP_STATES)}
                                                             onChange={setState}
                                                             converter={r => r}/>}
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col xs={12} lg={8}>
                                <div className="mt-2 mb-3"><span className="h4">Anmerkungen</span></div>
                                <div><span><Input type="textarea" onChange={e => setNotes(e.target.value)}/></span>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <span className="text-danger pull-left">{error}</span>
                        <Button className="pull-right" outline color="success"
                                onClick={save}>Speichern</Button>
                        <Button className="pull-right mr-3" outline color="danger"
                                onClick={back}>Zurück</Button>
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    </>
}

export default function TripDetailView(props) {
    const id = props.match.params.id;
    const [trip, setTrip] = useState();
    const [editing, setEditing] = useState(false);
    const [imageOpen, setImageOpen] = useState(-1);
    const [reportOpen, setReportOpen] = useState(null);
    const [reportEditOpen, setEditReportOpen] = useState(null);

    const [createFailed, setCreateFailed] = useState("");

    const save = (data, onError) => {
        if (trip) {
            update("/trips/" + trip.id, data).then(r => {
                setEditing(false);
                setTrip(r.trip);
            })
                .catch(err => onError(err.message));
        } else {
            create("/trips/", data).then(r => {
                setEditing(false);
                setTrip(r.trip);
                history.push(window.location.pathname.replace("create", r.trip.id));
            })
        }
    };

    useEffect(() => {
        if (id !== "create") {
            get("/trips/" + id).then(r => setTrip(r.trip)).catch(err => {
            });
        } else {
            setEditing(true);
        }
    }, [reportEditOpen]);

    function createReport() {
        create('/trips/' + id + '/report/', {draft: true}).then(r => {
            // history.push(window.location.pathname + "/createReport?id=" + r.id);
            console.log(r);
            setEditReportOpen(r);
        }).catch(err => setCreateFailed(err))
    }

    function closeTrip(trip) {
        if (trip) {
            save({
                state: trip.state + 1
            }, () => {
            });
        }
    }

    return (
        <div className="content">
            <Collapse isOpen={trip && !editing && !reportEditOpen}>{trip && !editing && <>
                <Row className="mb-3">
                    <Col>
                        <h1 className="mb-0">Kontrollfahrt</h1><br/>
                        <h2 className="mt-0">{new Date(trip?.date).toLocaleDateString()}</h2>
                    </Col>
                </Row>
                {/*  ---- Details of the Trip ----  */}
                <Row>
                    <Col xs={12}>
                        <Card className="my-2">
                            <CardHeader className="align-content-center align-items-center">
                                <span className="h3">Details</span>
                                {/*<Button className="pull-right" outline color="default"*/}
                                {/*        onClick={e => action(e, () => setEditing(true))}>Bearbeiten</Button>*/}
                            </CardHeader>
                            <CardBody><Row>
                                <Col xs={12} lg={6}>
                                    <Table borderless striped={false}>
                                        <tbody>
                                        <tr>
                                            <td className="text-muted" align={"right"}>Strecke</td>
                                            <td>
                                                <big>{trip?.route?.name}</big>
                                                {trip?.section && <><br/><big>{trip.section.name}</big></>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted" align={"right"}>Datum</td>
                                            <td><big>{new Date(trip?.date).toLocaleDateString()}</big></td>
                                        </tr>
                                        {trip?.state !== 0 && <tr>
                                            <td className="text-muted" align={"right"}>Status</td>
                                            <td><big>{strings().getString(TRIP_STATES)[trip?.state]}</big></td>
                                        </tr>}
                                        <tr>
                                            <td className="text-muted" align={"right"}>Scout</td>
                                            <td><big>{trip?.scout.name}</big></td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                {trip?.notes && <Col>
                                    <span className="h4">Anmerkungen</span><br/>
                                    <span>{trip?.notes}</span>
                                </Col>}
                            </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12}>
                        {createFailed && <span className="text-danger font-weight-bold font-italic">
                            Fehler beim Erstellen der Meldung!
                        </span>}
                        <div className="w-100 d-flex my-2">{trip?.state === 0 &&
                            <Button className="flex-grow-1" outline color="success"
                                    onClick={e => action(e, createReport)}><span
                                className="mx-auto">Neue Meldung</span>
                            </Button>}</div>
                    </Col>
                    <Col xs={12}>
                        <Card>
                            <CardHeader className="align-content-center align-items-center">
                                <span className="h3">Meldungen</span>
                            </CardHeader>
                            <CardBody className="mb-3 mx-3">
                                {trip.state === 0 && <div>
                                    {trip.reports.map(report =>
                                        <ButtonGroup key={report.id} className="d-flex my-0">
                                            <Button outline color="default"
                                                    className="flex-grow-1 d-flex my-1 px-2 text-left align-content-center align-items-center"
                                                    onClick={e => action(e, () => setEditReportOpen(report))}>
                                                <span
                                                    className="mr-auto flex-grow-1">{report.error && report.error?.name || "Keine Art verfügbar"}</span>
                                                &nbsp;
                                                <FontAwesomeIcon className="mx-1"
                                                                 icon="exclamation-triangle" /*comment-alt*/
                                                                 style={{color: "#" + report?.severity?.color || "#ECECEC"}}/>
                                                &nbsp;
                                                <FontAwesomeIcon
                                                    className={"" + (report.imageIds?.length > 0 ? "text-success" : "text-danger")}
                                                    icon="images"/>
                                            </Button>
                                            <Button className="flex-grow-0 text-center my-1" outline size="sm"
                                                    style={{width: 48}}
                                                    color={report.draft ? "warning" : "info"}
                                                    onClick={e => action(e, () => setReportOpen(report))}>
                                                <FontAwesomeIcon icon="info" className="mx-1"/>
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                </div>}

                                {reportOpen && <ReportDetailsModal report={reportOpen} tripId={id}
                                                                   onClose={() => setReportOpen(null)}/>}

                                {trip.state !== 0 && <Table borderless striped={false}>
                                    <tbody>
                                    {trip.reports.map(report => <tr key={report.id}>
                                        <td>{report.error && report.error?.name || "Keine Art verfügbar"}</td>
                                        <td width="1" className="text-nowrap">
                                            <FontAwesomeIcon className="mx-1" icon="comment-alt"
                                                             style={{color: "#" + report?.severity?.color || "#ECECEC"}}/>
                                            <FontAwesomeIcon
                                                className={"mx-1 " + (report.images > 0 ? "text-success" : "text-danger")}
                                                icon="images"/>
                                            <Button className="mx-1" outline size="sm"
                                                    color={report.draft ? "warning" : "info"}
                                                    onClick={e => action(e, () => setReportOpen(report))}>
                                                <FontAwesomeIcon icon="info"/>
                                            </Button>
                                        </td>
                                        {reportOpen && <ReportDetailsModal report={reportOpen} tripId={id}
                                                                           onClose={() => setReportOpen(null)}/>}
                                    </tr>)}
                                    </tbody>
                                </Table>}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12}>
                        <div className="w-100 d-flex my-2">{trip?.state === 0 &&
                            <Button className="flex-grow-1" outline color="info"
                                    onClick={e => action(e, closeTrip(trip))}><span
                                className="mx-auto">Abschließen</span>
                            </Button>}</div>
                    </Col>
                </Row>
            </>}</Collapse>
            {/*  ---- Trip Report Edit Collapse ----  */}
            <Collapse isOpen={!!trip && !!reportEditOpen}>
                {reportEditOpen &&
                    <CreateTripReport report={reportEditOpen} tripId={trip.id} onBack={() => setEditReportOpen(null)}
                                      wrapped={true}/>}
            </Collapse>
            {imageOpen > 0 && <Modal isOpen={imageOpen > 0} toggle={() => setImageOpen(-1)}>
                <ModalHeader>
                    <h4>Bild hochladen</h4>
                </ModalHeader>
                <ModalBody>
                    <Form method="post" enctype="multipart/form-data"
                          action={"/api/v1/trips/images"}>
                        <input type="hidden" name="report" value={imageOpen}/>
                        <input type="file" name={"file"} onChange={e => {
                        }}/>
                        <Button type="submit">Senden</Button>
                    </Form>
                </ModalBody>
            </Modal>}
            {editing && <EditTripView trip={trip} publisher={save} back={e => action(e, () => setEditing(false))}/>}
        </div>
    )
}
