import React from "react";
import {NavLink} from "react-router-dom";
import {Nav} from "reactstrap";
// javascript plugin used to create scrollbars on windows
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getMenu, MenuEntry} from "../../variables/menu";

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.activeRoute.bind(this);
        this.sidebar = React.createRef();
        this.mounted = false;
    }

    // verifies if routeName is the one active (in browser input)
    activeRoute(routeName) {
        return routeName === "/" ?
            (window.location.pathname.endsWith("/") ? "active" : "") :
            (window.location.pathname.indexOf(routeName) > -1 ? "active" : "");
    }

    render() {
        return (
            <div
                className="sidebar"
                data-color={this.props.bgColor}
                data-active-color={this.props.activeColor}
            >
                <div className="logo text-white text-center align-middle">
                    <a
                        href="https://radscout.urschick.at"
                        className="simple-text logo-normal"
                    >
                        <FontAwesomeIcon icon={"bicycle"} className="align-middle logo-img mr-2" size="1x"/>
                        Radscout 21
                        <FontAwesomeIcon icon={"bicycle"} className="align-middle logo-img ml-2" size="1x"/>
                    </a>
                </div>
                <div className="sidebar-wrapper" ref={this.sidebar}>
                    <Nav>
                        {getMenu(this.props.role).map((prop, key) => <MenuEntry entry={prop} key={key}
                                                                                active={this.activeRoute}/>)}
                    </Nav>
                </div>
            </div>
        );
    }
}

export default Sidebar;
