import React from "react";
import {NavLink} from "react-router-dom";
import {Redirect, Route as R} from "react-router";

import WelcomeDashboard from "../views/Dashboard";
import TripOverview from "../views/trip/TripOverview";
import {AdminLayout} from "../layouts/Admin";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {sha512} from "js-sha512";
import TripDetailView from "../views/trip/TripDetailView";
import CreateTripReport from "../views/trip/CreateTripReport";
import ReportDashboard from "../views/reporting/ReportDashboard";
import TripReportsTable from "../views/reporting/TripReportsTable";

class Route {
    constructor(path, exact, component, roles) {
        this.path = path;
        this.exact = exact;
        this.Component = component;
        this.roles = roles;
    }

    convertToRoute(canAccess, getUser) {
        const C = this.Component;
        if (canAccess && canAccess(this.roles)) {
            return (<R key={sha512(this.path)} path={this.path} exact={this.exact} render={props => {
                return (<AdminLayout user={getUser()}><C {...props} user={getUser()}/></AdminLayout>)
            }}
            />);
        }
    }
}

class MenuHolder {
    constructor(key, icon, route, role) {
        this.key = key;
        this.icon = icon;
        this.route = route;
        this.roles = role;
    }
}

const ROUTES = [
    new Route("/", true, WelcomeDashboard, ["admin", "master", "scout"]),
    new Route("/trip", true, TripOverview, ["admin", "master", "scout"]),
    new Route("/trip/:id", true, TripDetailView, ["admin", "master", "scout"]),
    new Route("/trip/:id/createReport", true, CreateTripReport, ["admin", "master", "scout"]),
    new Route("/reporting", true, ReportDashboard, ["admin", "master", "department", "agent"]),
    new Route("/reporting/trTable", true, TripReportsTable, ["admin", "master", "department", "agent"]),
];

const menuEntries = {
    admin: [
        new MenuHolder("Home", "", "/", "admin"),
        new MenuHolder("Archiv", "", "/trip", "admin"),
        new MenuHolder("Berichte", "", "/reporting", "admin"),
    ],

    master: [
        new MenuHolder("Home", "", "/", "master"),
        new MenuHolder("Archiv", "", "/trip", "master"),
        new MenuHolder("Berichte", "", "/reporting", "master"),
    ],

    department: [
        new MenuHolder("Berichte", "", "/reporting", "department"),
    ],

    agent: [],

    scout: [
        new MenuHolder("Home", "", "/", "scout"),
        new MenuHolder("Archiv", "", "/trip", "scout"),
    ]
};

const getMenu = (role) => {
    return menuEntries[role];
}

const getRoutes = (accessHandle, getUser) => {
    const r = ROUTES.map(role => role.convertToRoute(accessHandle, getUser))
    if (r && r.length > 0) {
        return r;
    }
    return null;
}

function MenuEntry({entry, active}) {
    return (<li
        className={
            active(entry.route)
        }
    >
        <NavLink
            to={entry.route}
            className="nav-link"
            activeClassName="active"
        >
            <p>{entry.icon && <FontAwesomeIcon icon={entry.icon} size="lg" className="mr-3"/> + (" ")}{entry.key}</p>
        </NavLink>
    </li>)
}

const APP_NAME = "Radscout 21";

export
{
    getRoutes, getMenu, MenuEntry, APP_NAME
}
