import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from "reactstrap";
import {API_URL, create, get, update} from "../../variables/backend";
import strings, {REPORT_STATES, TRIP_STATES} from "../../assets/lang/localization";
import BootstrapTable from 'react-bootstrap-table-next';
import {action} from "../../variables/toolkit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import filterFactory, {selectFilter} from "react-bootstrap-table2-filter";
import paginationFactory from 'react-bootstrap-table2-paginator';
import {useSelector} from "react-redux";
import * as PropTypes from "prop-types";
import {missingBinary} from "node-sass/lib/errors";

const dateFormatter = (cell, row, rowIdx, formatExtraData) => new Date(cell).toLocaleDateString();

function ReportDetailsModal({report, onClose}) {
    const hasCoordinates = () => !!(report.location?.lat && report.location?.lon);

    const createCoordinatesElement = () => hasCoordinates() ? <Button outline color="default" onClick={(e) => action(e, () => {
        if (hasCoordinates()) {
            window.open(
                `https://maps.google.com/?q=${report.location?.lat},${report.location?.lon}`,
                "none"
            )
        }
    })}>
        {report.location.lat + " / " + report.location.lon}
    </Button> : <span><big>Keine Koordinaten verfügbar!</big></span>

    return <Modal isOpen={true} toggle={onClose} size="lg">
        <ModalHeader><span className="h2">Meldungsdetails</span></ModalHeader>
        <ModalBody>
            {report.draft &&
                <span className="mb-4 font-italic">Diese Meldung ist ein Entwurf und wird nach Abschluss der Kontrollfahrt wieder gelöscht!</span>}
            {<>
                <div>
                    <span className="text-muted">Koordinaten</span><br/>
                    {createCoordinatesElement()}
                </div>
                <div className="mt-2">
                    <span className="text-muted">Kategorie</span><br/>
                    <span><big>{report.error?.name || "Keine Kategorie"}</big></span>
                </div>
                {report.severity && <div className="mt-2">
                    <span className="text-muted">Dringlichkeit</span><br/>
                    <span><big>{report.severity.name}</big></span>
                </div>}
                {report.notes?.scout && <div className="mt-2">
                    <span className="text-muted">Notizen</span><br/>
                    <span><big>{report.notes?.scout}</big></span>
                </div>}
                {report?.imageIds?.map(id => <img alt="Bild" key={id} className="my-3"
                                                  src={API_URL("/trips/" + report.trip.id + "/reports/" + report.id + "/image/" + id)}/>)}

                {report.assignedTo?.group && <div className="mt-2">
                    <span className="text-muted">Zugewiesen an Gruppe</span><br/>
                    <span><big>{report.assignedTo?.group?.name}</big></span>
                </div>}
                {report.assignedTo?.user && <div className="mt-2">
                    <span className="text-muted">Zugewiesen an Person</span><br/>
                    <span><big>{report.assignedTo?.user?.name}</big></span>
                </div>}
            </>}
        </ModalBody>
        <ModalFooter>
            <Button className="w-100" outline color="default" onClick={e => action(e, () => onClose())}><span
                className="mx-auto">Schließen</span></Button>
        </ModalFooter>
    </Modal>;
}

let routeFilter, scoutFilter, severityFilter, errorFilter;

function AssignReportModal({onClose, onReload, report, groups}) {
    const publishAssignGroup = (gId) => {
        create("/trips/" + report.trip.id + "/report/" + report.id + "/assign/", {groupId: gId}).then(r => onReload && onReload())
    }

    return <Modal isOpen={true} toggle={onClose}>
        <ModalHeader>
            <span className="h3">Meldung zuweisen</span>
        </ModalHeader>
        <ModalBody>
            <div className="w-100">
                <span className="h4 text-secondary">Gruppen</span>
                <div className="d-flex flex-column container-fluid">
                    {groups && groups.map(g => <div key={g.id} className="w-100 d-flex align-items-center align-content-center">
                        <span className="mr-auto flex-grow-1">{g.name}</span>
                        <Button active={report.assignedTo?.group?.id === g.id} outline color="default" size="sm" className="px-2 py-1" onClick={e => action(e, () => publishAssignGroup(g.id))}><FontAwesomeIcon icon={"check"}/></Button>
                    </div>)}
                </div>
            </div>
        </ModalBody>
        <ModalFooter>
            <Button className="w-100" outline color="default" onClick={e => action(e, () => onClose())}><span
                className="mx-auto">Schließen</span></Button>
        </ModalFooter>
    </Modal>;
}

AssignReportModal.propTypes = {
    groups: PropTypes.arrayOf(PropTypes.any),
    onClose: PropTypes.func,
    report: PropTypes.any
};
export default function (props) {
    const [reports, setReports] = useState();
    const [routes, setRoutes] = useState([]);
    const [scouts, setScouts] = useState([]);
    const [severities, setSeverities] = useState([]);
    const [errors, setErrors] = useState([]);
    const [groups, setGroups] = useState([]);
    const [openReport, setOpenReport] = useState(null);
    const [assignOpen, setAssignOpen] = useState(null);

    const isAssigned = (report) => {
        return report.assignedTo && (report.assignedTo.group || report.assignedTo.user);
    }

    const missing = (report) => {
        let missing = 0;

        if (report.draft) return -1;

        if (!report.severity) missing++;
        if (!report.error) missing++;
        if (!report.imageIds?.length) missing++;
        if (!report.coordinates?.lon || !report.coordinates?.lat) missing++;

        return missing;
    }

    const missingToColor = (report) => {
        const miss = missing(report);

        if (miss) {
            if (miss > 3) return "error"
            if (miss < 0) return "info"
            return "warning"
        }
        return "default"
    }

    const columns = [
        {
            dataField: "trip.date", text: "Datum",
            formatter: dateFormatter, sort: true, sortValue: (cell, row) => new Date(cell).getTime()
        },
        {
            dataField: "trip.route.id", text: "Route", sort: true,
            formatter: ((cell, row, rowIdx, formatExtraData) => row.trip.route.name),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    e.preventDefault();
                    routeFilter(row.trip?.route?.id)
                }
            },
            filter: selectFilter({
                options: (column) => routes?.map(r => {
                    return {value: r.id, label: r.name}
                }),
                getFilter: (filter) => {
                    routeFilter = filter
                },
                className: "p-1 w-100 mx-auto d-block"
            })
        },
        {
            dataField: "trip.scout.id", text: "Scout", sort: true,
            formatter: ((cell, row, rowIdx, formatExtraData) => row.trip.scout.name),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    e.preventDefault();
                    scoutFilter(row.trip?.scout?.id)
                }
            },
            filter: selectFilter({
                options: (column) => scouts?.map(r => {
                    return {value: r.id, label: r.name}
                }),
                getFilter: (filter) => {
                    scoutFilter = filter
                },
                className: ""
            })
        },
        {
            dataField: "error.id", text: "Kategorie", sort: true,
            formatter: ((cell, row, rowIdx, formatExtraData) => row.error?.name),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    e.preventDefault();
                    errorFilter(row.error?.id)
                }
            },
            filter: selectFilter({
                options: (column) => errors?.map(r => {
                    return {value: r.id, label: r.name}
                }),
                getFilter: (filter) => {
                    errorFilter = filter
                },
                className: ""
            })
        },
        {
            dataField: "severity.id", text: "Schweregrad", sort: true,
            formatter: ((cell, row, rowIdx, formatExtraData) =>
                row.severity && <span className="p-1 w-100 mx-auto d-block text-center" style={{
                    color: `#${row.severity?.color}`,
                    backgroundColor: "#23232340"
                }}>{row.severity?.name}</span>),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    e.preventDefault();
                    severityFilter(row.severity?.id)
                }
            },
            filter: selectFilter({
                options: (column) => severities?.map(r => {
                    return {value: r.id, label: r.name}
                }),
                getFilter: (filter) => {
                    severityFilter = filter
                },
                className: "p-1 w-100 mx-auto d-block"
            })
        },
        {
            dataField: "state", text: "Status", sort: true,
            formatter: ((cell, row, rowIdx, formatExtraData) => <span onClick={e => action(e, () => closeReport(row.trip.id, row.id))}
                                                                      className="p-1 border-bottom w-100 mx-auto d-block text-center">
                {strings().getString(TRIP_STATES)[cell]}
            </span>),
        },
        {
            dataField: "id", text: "", style: {width: "1px"}, classes: 'text-nowrap',
            formatter: (cell, row, rowIdx, formatExtraData) => <>
                <Button className="mx-2 p-1 px-2" outline color={missingToColor(row)} size="sm"
                        onClick={e => action(e, () => setOpenReport(cell))}><FontAwesomeIcon icon="info"/></Button>
                <Button className="mx-2 p-1 px-2" outline color={isAssigned(row) ? "success" : "default"} size="sm"
                        onClick={e => action(e, () => setAssignOpen(cell))}><FontAwesomeIcon icon="user-plus"/></Button>
            </>
        },
    ];

    const load = () => {
        get("/reporting/reports").then(result => setReports(result.reports));
    }

    const closeReport = (tripId, reportId) => {
        update("").then(r => load());
    }

    const downloadCsv = () => {
        window.open(API_URL("/reporting/reports/export"));
    }

    useEffect(() => {
        load();
        get("/fixture/", {include: 'routes,scouts,errorTypes,severities,groups'}).then(result => {
            setRoutes(result.routes)
            setScouts(result.scouts)
            setSeverities(result.severities)
            setErrors(result.errorTypes)
            setGroups(result.groups)
        });
    }, []);

    return <div className="content">
        <Row className="mb-3">
            <Col>
                <h1 className="mb-0 text-primary">Meldungen</h1><br/>
                <h2 className="mt-0 text-secondary">Statusmeldungen der Scouts</h2>
            </Col>
        </Row>
        {/*  ---- Details of the Trip ----*/}
        <Row>
            <Col>
                <Card>
                    {
                        reports && reports.length > 0 && routes && routes.length > 0 && scouts && scouts.length > 0 &&
                        severities && severities.length > 0 && errors && errors.length > 0 &&
                        <BootstrapTable filter={filterFactory()} wrapperClasses="m-3 d-none d-lg-block"
                                        selectRow={{
                                            mode: "checkbox",
                                            hideSelectColumn: true,
                                            clickToSelect: false,
                                            selected: [],
                                            onSelect: (row, isSelect, rowIndex, e) => setOpenReport(isSelect ? row.id : null)
                                        }} filterPosition="top"
                                        columns={columns} pagination={paginationFactory({
                            hideSizePerPage: true,
                            withFirstAndLast: false,
                            sizePerPage: 10,
                            showTotal: true,
                            paginationTotalRenderer: (from, to, size) => (
                                <span className="react-bootstrap-table-pagination-total mx-4">Zeige {from} bis {to} von insgesamt {size} Meldungen</span>
                            )
                        })}
                                        data={reports} keyField="id"
                        /> || <span className="text-warning font-italic m-3">Keine Meldungen</span>}
                </Card>
                {openReport && reports && <ReportDetailsModal onClose={() => setOpenReport(null)} report={reports?.find(r => r.id === openReport)}/>}
                {assignOpen && reports &&
                    <AssignReportModal groups={groups} onClose={() => setAssignOpen(null)} report={reports?.find(r => r.id === assignOpen)} onReload={load}/>}
            </Col>
        </Row>
    {/*  ---- Export Card ----  */}
        <Row>
            <Col>
                <Card>
                    <CardBody>
                        <span>CSV Export</span>
                        <Button outline color="default" onClick={e => action(e, downloadCsv)} className="pull-right">Meldungen herunterladen&nbsp;<FontAwesomeIcon icon={"download"}/></Button>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </div>
}

/*

<thead>
                            <tr>
                                <th>Datum</th>
                                <th>Route</th>
                                <th>Scout</th>
                                <th>Kategorie</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                reports.map(report => <tr key={report.id}>
                                    <td>{new Date(report.trip.date).toLocaleDateString()}</td>
                                    <td>{report.trip.route.name}</td>
                                    <td>{report.trip.scout.name}</td>
                                    <td>{report.error.name}{report.severity && " / " + report.severity.name}</td>
                                    <td>{strings().getString(REPORT_STATES)[report.state]}</td>
                                </tr>)
                            }
                            </tbody>


*
* */
