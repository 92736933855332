export default {
    "trip.states": [
        "Offen",
        "Vorgemerkt",
        "In Bearbeitung",
        "Alle Meldungen abgeschlossen"
    ],
    "report.states": [
        "Offen",
        "Vorgemerkt",
        "In Bearbeitung",
        "Erledigt"
    ]
};
