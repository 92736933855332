import {createStore} from "redux";

const initialState = {
    errorTypes: [],
    severities: [],
    routes: [],
    trips: {},
    getTrip: (id) => this.trips?.find(t => t.id === id),
    reloadApi: () => {},
};

const dispatch = (state = initialState, {type, ...rest}) => {
    switch (type) {
        case 'init':
            return {
                ...state,
                ...rest.data
            }
        case 'setReload':
            return {
                ...state,
                reloadApi: rest.data,
            }
        default:
            return {
                ...state
            }
    }
};

export default createStore(dispatch);
