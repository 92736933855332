import React, {useEffect, useState} from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import {notificationHandle, setTheme} from "../variables/theme";
import {auth} from "../variables/authentication";
import Auth from "./Auth";
import NotificationAlert from "react-notification-alert/index";
import {getRoutes} from "../variables/menu";
import {TESTING, get} from "../variables/backend";
import {Provider, useDispatch} from "react-redux";
import fixtureStore from "../variables/fixtureStore";

function RoutingWrapper(props) {
    const [user, setUserState] = useState(null);
    const [routes, setRoutes] = useState([]);

    const checkUser = (roles) => {
        if (TESTING) {
            return true;
        }
        if (!user) {
            return false;
        }
        return roles.includes(user.role);
    };
    const setUser = (model) => {
        if (model && model.user) {
            setUserState(model.user);
        } else {
            setUserState(null);
        }
    }

    useEffect(() => {
        auth.attemptLogin(null, setUser).catch(err => {});
    }, []);

    useEffect(() => {
        setRoutes(getRoutes(checkUser, () => user));
    }, [user]);

    return (
        <Switch>
            <Route path="/logout" exact render={(props) => {
                auth.logout(setUser);
                return <Redirect {...props} to={"/"}/>;
            }}/>
            {!routes.isOnlyUndefined() && routes || <Route path="/" exact render={(props) => <Auth {...props}
                                                                                                   attemptLogin={(user, pass, hook) => auth.attemptLogin({
                                                                                                       userRaw: user,
                                                                                                       passwordRaw: pass,
                                                                                                       remember: false
                                                                                                   }, (model) => {
                                                                                                       setUser(model);
                                                                                                       if (hook) {
                                                                                                           hook(model);
                                                                                                       }
                                                                                                   }).catch(err => {})}/>}/>}
        </Switch>
    );
}

function InitialLoadWrapper(props) {
    const dispatch = useDispatch();
    const reload = () => {
        get('/fixture/?include=trips,routes,errorTypes,severities').then(data => {
            dispatch({
                type: 'init',
                data: {
                    ...data,
                    reloadApi: reload
                }
            })
        })
    }

    useEffect(() => {
        reload();
    }, []);

    return <>
        {props.children}
    </>
}

function AdminLayout(props) {
    const [state, setState] = useState({
        theme: "white"
    })

    const changeTheme = () => {
        setTheme(state.theme === "white" ? "black" : "white");
        setState({theme: state.theme === "white" ? "black" : "white"});
    }

    if (!props.user) return null;
    return (
        <div className="wrapper">
            <NotificationAlert ref={notificationHandle}/>
            <Sidebar
                {...props}
                bgColor={state.theme}
                activeColor={"primary"}
                role={props.user.role}
            />
            <div className="main-panel" data-theme={state.theme}>
                <DemoNavbar {...props} routes={state.routes}
                            user={props.user}
                            themeSwitch={() => changeTheme()}/>
                <Provider store={fixtureStore}>
                    <InitialLoadWrapper>{props.children}</InitialLoadWrapper>
                </Provider>
                <Footer fluid/>
            </div>
        </div>
    );
}

export {AdminLayout, RoutingWrapper};
