import React from "react";
// reactstrap components
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row} from "reactstrap";

import Footer from "../components/Footer/Footer";
import {auth} from "../variables/authentication";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: "",
            password: "",
            message: "",
            remember: false
        }
    }

    loginModelChanged(model) {
        //if (this && this.setState) {
            console.log("updating state in auth layout")
            this.setState({message: model.message})
        //}
    }

    // componentDidMount() {
    //     auth.addUpdateSubscription(this.loginModelChanged)
    // }
    //
    // componentWillUnmount() {
    //     auth.removeUpdateSubscription(this.loginModelChanged)
    // }

    doLogin = () => {
        this.props.attemptLogin(
            this.state.user,
            this.state.password,
            (model) => this.loginModelChanged(model)
        )
    }

    render() {
        return (
            <>
                <Col lg="5" md="7">
                    <Card className="shadow border-0">
                        <CardHeader className="bg-transparent">
                            <div className="h1 text text-center mb-0">
                                <small>Login</small>
                            </div>
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-3">
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                this.doLogin();
                            }}>
                                <Row className="my-2">
                                    <Col className="px-4">
                                        <FormGroup>
                                            <label>Benutzername</label>
                                            <Input
                                                placeholder="Benutzer"
                                                type="text"
                                                onChange={event => this.setState({user: event.target.value})}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="my-2">
                                    <Col className="px-4">
                                        <FormGroup>
                                            <label>Passwort</label>
                                            <Input
                                                placeholder="Passwort"
                                                type="password"
                                                onChange={event => this.setState({password: event.target.value})}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {this.state.message && <span className="text-danger text-center w-100 mx-auto">{this.state.message}</span>}
                                <Row className="my-2">
                                    <Col xs="12" className="px-2">
                                        <Container className="d-flex align-items-center align-content-center">
                                            <Button className="flex-grow-1 my-4" color="success" outline type="submit">
                                                <span className={"mx-auto"}>Login</span>
                                            </Button>
                                        </Container>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </>
        );
    }
}

class Auth extends React.Component {
    componentDidMount() {
        document.body.classList.add("bg-light");
    }

    componentWillUnmount() {
        document.body.classList.remove("bg-light");
    }

    render() {
        return (
            <>
                <Container>
                    <div className="header-body text-center mb-1 mt-4">
                        <Row className="justify-content-center">
                            <Col lg="5" md="6">
                                <h1 className="text-primary mb-0 pb-0 bg-light text-uppercase">Radscout</h1>
                                <h4 className="text-primary mt-0 pt-2 bg-light">Radrouten Manager</h4>
                            </Col>
                        </Row>
                    </div>
                </Container>
                {/* Page content */}
                <Container className="mt-0 pb-0">
                    <Row className="justify-content-center">
                        <Login attemptLogin={this.props.attemptLogin}/>
                    </Row>
                </Container>
                <Footer/>
            </>
        );
    }
}

export default Auth;
