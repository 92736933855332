/*<editor-fold desc="Functional Stuff">*/
const MONTHS = [
    "Jänner",
    "Feber",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember"
];
const WEEKDAYS = [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
];

const formatDateToString = (date, showDate = false) => {
    if (typeof date === "number") {
        date = new Date(date);
    }

    if (showDate) {
        return `${date.getDate()}. ${MONTHS[date.getMonth()]} ${date.getFullYear()}`;
    }
    return `${MONTHS[date.getMonth()]} ${date.getFullYear()}`;
}

const toDoubleDigit = (value) => {
    if (value < 10) {
        return "0" + value
    }
    return value
}

const formatTimeToString = (t, doubleDigit = true) => {
    if (typeof t === "number") {
        t = new Date(t);
    }

    if (doubleDigit)
        return `${toDoubleDigit(t.getHours())}:${toDoubleDigit(t.getMinutes())}`;
    return `${t.getHours()}:${t.getMinutes()}`;

}

const formatPerson = (person, useShortNames) => {
    if (!person)
        return ""
    return useShortNames && person.shortName ? person.shortName : person.displayName;
}
/*</editor-fold>*/

const groupBy = function(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
};

const doNothingAction = e => {
    e.target.blur();
    e.preventDefault();
}

const action = (e, action) => {
    doNothingAction(e);
    if (action) {
        action(e);
    }
}

export {
    formatDateToString,
    formatPerson,
    formatTimeToString,
    toDoubleDigit,
    groupBy,
    MONTHS,
    WEEKDAYS,
    doNothingAction,
    action
}
