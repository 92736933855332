import React from "react";
import axios from "axios";

export const TESTING = false;
const HTTPS=false;

export const API_URL = (endpoint) => {
    return `/api/v1${endpoint}`;
    // if (TESTING) return `http://192.168.8.84:5000/api/v1${endpoint}`;
    // return `${HTTPS ? "https" : "http"}://${window.location.hostname}/api/v1${endpoint}`;
};

const get = async (endpoint, params = null) => {
    return await axios.get(
        API_URL(endpoint),
        {
            responseType: "json",
            params: params
        }
    ).then(r => r.data);
};

const create = async (endpoint, object) => {
    return await axios.post(
        API_URL(endpoint),
        object,
        {
            responseType: "json"
        }
    ).then(r => r.data);
};

const update = async (endpoint, object, params) => {
    return await axios.put(
        API_URL(endpoint),
        object,
        {
            responseType: "json",
            params: params
        }
    ).then(r => r.data);
};

const remove = async (endpoint, params = null) => {
    return await axios.delete(
        API_URL(endpoint),
        {
            responseType: "json",
            params: params
        }
    ).then(r => r.data);
};

class APIError extends Error {
    constructor(response) {
        super();
        this.backendResponse = JSON.parse(response.message);
    }

    getResponse() {
        return this.backendResponse;
    }

    getError() {
        if (this.getResponse().error)
            return this.getResponse().error;
        return this.getResponse;
    }

    getCode() {
        return this.getResponse().code;
    }

    copyToClipboard() {
        navigator.clipboard.writeText(JSON.stringify(this.getResponse())).then(
            res => console.log(res)
        );
    }
}

export {get, create, update, remove, APIError}
