import React, {useState, useEffect} from "react";
import {Badge, Button, Col, Form, FormGroup, Input, InputGroup, Row, Table} from "reactstrap";
import {action} from "../../variables/toolkit";
import GalleryUpload from "react-upload-gallery";
import 'react-upload-gallery/dist/style.css'
import {API_URL, create, get, remove} from "../../variables/backend";
import history from "../../variables/history";
import InputSelect from "../../components/Forms/InputSelect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function OverviewStep({trip, overview}) {
    return (<div>
        <div>
            {trip && <Form>
                <FormGroup>
                    <label>Scout</label>
                    <Input disabled value={trip.scout.name}/>
                </FormGroup>
                <FormGroup>
                    <label>Datum</label>
                    <Input disabled value={new Date(trip.date).toLocaleDateString()}/>
                </FormGroup>
                <FormGroup>
                    <label>Route</label>
                    <Input disabled value={trip.route.name}/>
                </FormGroup>
                {trip.section && <FormGroup>
                    <label>Abschnitt</label>
                    <Input disabled value={trip.section.name}/>
                </FormGroup>}
            </Form> || <span>Keine Meldungsdetails! Meldung erstellen wird fehlschlagen...</span>}
        </div>
    </div>)
}

function LocationStep({latitude, longitude, setLatitude, setLongitude, location}) {
    const [err, setErr] = useState(null);
    const [manualMode, setManualMode] = useState(false);

    const setLocation = e => {
        action(e, () => {
            navigator.geolocation.getCurrentPosition((pos) => {
                setErr(null);
                setLatitude(pos.coords.latitude + "");
                setLongitude(pos.coords.longitude + "");
            }, err => {
                setErr(err.code === err.PERMISSION_DENIED ? "Zugriff auf Standortdienste verweigert!" : "Fehler beim Lesen des Standorts!");
            })
        });
    }

    const manualAvailable = true; // location && location.manualEnabled;

    return <>
        <div>
            <Form>
                {
                    err && <>
                        <span className="text-danger">{err}</span><br/>
                        <span className="text-warning font-italic">Standort kann manuell eingegeben werden.</span>
                    </>
                }
                <Row>
                    <Col>
                        <FormGroup>
                            <label>Latitude</label>
                            <Input disabled={!manualMode} value={latitude} onChange={e => setLatitude(e.target.value)}/>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <label className="pull-right">Longitude</label>
                            <Input disabled={!manualMode} className="text-right" value={longitude}
                                   onChange={e => setLongitude(e.target.value)}/>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </div>
        <div className="d-flex">
            <Button onClick={setLocation} outline color="info" className="mx-auto flex-grow-1">Standort
                ermitteln</Button>
            {manualAvailable &&
                <Button onClick={e => action(e, () => setManualMode(!manualMode))} outline
                        color="default" className="ml-2">
                    <FontAwesomeIcon icon="edit"/>
                </Button>}
        </div>
    </>
}

function PictureStep({trip, reportId}) {
    const [images, setImages] = useState();
    const [uploadState, setUploadState] = useState();

    const uploadImage = (event) => {
        const destination = "/trips/" + trip.id + "/report/" + reportId + "/image/"
        event.preventDefault();

        let formData = new FormData();
        formData.append("reportId", reportId);
        formData.append("tripId", trip.id);
        for (let f of event.target.file.files) {
            formData.append("file", f)
        }
        // .forEach(f => formData.append("file", f))

        create(destination, formData)
            .then(res => {
                //event.target.file.clear()
                setUploadState("success");
                loadImages();
            })
            .catch(err => {
                setUploadState("error");
            })
    }
    const deleteImage = (id) => {
        remove("/trips/" + trip.id + "/reports/" + reportId + "/images/" + id).then(r => loadImages()).catch(err => console.log(err));
    }

    const loadImages = () => {
        get("/trips/" + trip.id + "/reports/" + reportId + "/image/").then(r => setImages(r.images));
    }

    useEffect(() => {
        if (reportId) {
            loadImages();
        }
    }, [reportId])

    return (<>
        <Row className="">
            <Col>
                {uploadState && uploadState === "success" && <span className="text-success">Hochladen des Bildes erfolgreich!</span>}
                {uploadState && uploadState === "error" && <span className="text-danger font-italic">Hochladen des Bildes fehlgeschlagen!</span>}
            </Col>
        </Row>
        <Row className="">
            <Col>
                <Form method="post" enctype="multipart/form-data" onSubmit={uploadImage}>
                    <input type="file" name={"file"}/>
                    <Button type="submit">Hochladen</Button>
                </Form>
            </Col>
        </Row>
        {images && images.length > 0 && <Row className="">
            <Col>
                {
                    images?.map(img => <div key={img.id}>
                        <div className="d-flex">
                            <span>{img.name}</span>
                            <Button size="sm" outline color="danger" onClick={e => action(e, () => deleteImage(img.id))}><FontAwesomeIcon icon={"trash"}/></Button>
                        </div>
                        <img src={API_URL("/trips/" + trip.id + "/reports/" + reportId + "/image/" + img.id)} alt={img.name}/>
                    </div>)
                }
            </Col>
        </Row>}
    </>)
}

function ErrorsStep({severities, errorTypes, setSeverity, setErrorType, errorType, severity, errors}) {
    return (<>
        <Table borderless>
            <tbody>
            <tr>
                {/*<td width="1" className="text-nowrap" align={"right"}>*/}
                {/*    <span>Kategorie</span>*/}
                {/*</td>*/}
                <td width="*">
                    <InputSelect className="d-flex" onChange={setErrorType} defaultValue={errorType}
                                 options={errorTypes} converter={e => e.name}/>
                </td>
            </tr>
            {/*<tr>*/}
            {/*    <td width="1" className="text-nowrap" align={"right"}>*/}
            {/*        <span>Dringlichkeit</span>*/}
            {/*    </td>*/}
            {/*    <td width="*">*/}
            {/*        <InputSelect className="d-flex" onChange={setSeverity} defaultValue={severity}*/}
            {/*                     options={severities} converter={s => s.name}/>*/}
            {/*    </td>*/}
            {/*</tr>*/}
            </tbody>
        </Table>
    </>)
}

function RemarksStep({note, setNote, remarks}) {
    return (<>
        <FormGroup>
            <label className="">Anmerkungen</label>
            <Input onChange={e => setNote(e.target.value)} type="textarea" value={note}/>
        </FormGroup>
    </>)
}

function AdditionalStep({
                            severities,
                            errorTypes,
                            setSeverity,
                            setErrorType,
                            errorType,
                            severity,
                            errors,
                            note,
                            setNote,
                            remarks
                        }) {
    return <>
        <ErrorsStep severities={severities} errors={errors} errorType={errorType} setErrorType={setErrorType}
                    errorTypes={errorTypes} setSeverity={setSeverity} severity={severity}/>
        <RemarksStep note={note} remarks={remarks} setNote={setNote}/>
    </>
}

const STEPS = [
    {
        name: "Übersicht",
        title: "Meldungsübersicht",
        optional: false,
        component: OverviewStep,
        widths: {
            lg: 5,
            xs: 12
        }
    },
    {
        name: "Standort",
        title: "Standort",
        optional: false,
        component: LocationStep,
        widths: {
            lg: 7,
            xs: 12
        }
    },
    {
        name: "Foto erstellen",
        title: "Fotos",
        optional: false,
        component: PictureStep,
        widths: {
            xl: 5,
            lg: 6,
            xs: 12
        }
    },
    {
        name: "Kategorie",
        title: "Kategorie",
        optional: false,
        component: AdditionalStep,
        widths: {
            xl: 7,
            lg: 6,
            xs: 12
        }
    },
    // {
    //     name: "Zusammenfassung",
    //     title: "Zusammenfassung",
    //     optional: false,
    //     component: SummaryStep,
    //     widths: {
    //         lg: 12,
    //         xs: 12
    //     }
    // },
]

const stepToColor = (act, step) => {
    return act === step ? "success" : step < act ? "info" : "secondary";
}

const renderStep = (idx, state) => {
    const step = STEPS[idx];
    const Component = step.component;
    return (
        <>
            <Col {...step.widths}><Row className="mx-2 mt-4">
                <Col>
                    <div className="d-flex text-primary"><h2
                        className="text-center flex-grow-1">{state.nameIsTitle ? step.name : step.title}</h2></div>
                    {step.optional && <div className="d-flex text-secondary mt-n3 pt-0"><h4
                        className="text-center flex-grow-1 mt-n2">(optional)</h4></div>}
                </Col>
            </Row>
                <Row>
                    <Col className="mx-2">
                        <Component name={step.name} {...state}/>
                    </Col>
                </Row></Col>
        </>
    )
}

export {
    renderStep, STEPS,
};

export default function TripReportWizard({
                                             trip, reportId,
                                             latitude, setLatitude,
                                             longitude, setLongitude,
                                             errorType, setErrorType, errorTypes,
                                             severity, setSeverity, severities,
                                             note, setNote,
                                             onSubmit, onBack,
                                             summary, remarks, errors, location
                                         }) {
    const [stepIdx, setStepIdx] = useState(1);

    const move = (i) => {
        if ((stepIdx + i) >= 0 && (stepIdx + i) < STEPS.length) {
            setStepIdx(stepIdx + i);
            window.moveTo(0, 0);
        } else if (stepIdx === 0) {
            onBack();
        } else if (stepIdx === STEPS.length - 1) {
            onSubmit();
        }
    };

    return (
        <div className="d-flex flex-column">
            <Row>
                <Col>
                    <div className="d-flex">
                        {STEPS.map((s, key) => <Badge key={key} color={stepToColor(stepIdx, key)} href="#"
                                                      onClick={e => action(e, () => setStepIdx(key))}
                                                      className="mx-2 px-2 py-1 flex-grow-1">{s.name}</Badge>)}
                    </div>
                </Col>
            </Row>
            <Row className="">{renderStep(stepIdx, {
                trip: trip, reportId: reportId,
                latitude: latitude, setLatitude: setLatitude,
                longitude: longitude, setLongitude: setLongitude,
                errorType: errorType, setErrorType: setErrorType, errorTypes: errorTypes,
                severity: severity, setSeverity: setSeverity, severities: severities,
                note: note, setNote: setNote,
                summary: summary, remarks: remarks, errors: errors, location: location
            })}</Row>
            <Row className="flex-grow-1 mt-auto">
                <Col>
                    <hr className="mx-2"/>
                    <div className="d-flex">
                        <Button outline color="default" className="flex-grow-1 mx-2"
                                onClick={e => action(e, () => move(-1))}>Zurück</Button>
                        <Button outline color="success" className="flex-grow-1 mx-2" disabled={!trip}
                                onClick={e => action(e, () => move(1))}>Weiter</Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
