import React, {useEffect, useState} from "react";
import {action} from "../../variables/toolkit";
import {Button, Input, Modal, ModalBody, Popover} from "reactstrap";

export default function ({options, defaultValue, onChange, converter, id, ...rest}) {
    const [popover, setPopover] = useState(false);
    const [selection, setSelection] = useState();

    useEffect(() => {
        // if (selection !== defaultValue) {
            setSelection(defaultValue);
        // }
    }, []);

    useEffect(() => {
        setPopover(false);
        onChange(selection);
    }, [selection]);

    return <span id={id} {...rest}>
        {popover && <Modal isOpen={popover} toggle={e => action(e, () => setPopover(!popover))}>
            <ModalBody>
                {options && options.map(o => <div className="d-flex"><Button color="default" outline className="flex-grow-1 mx-auto"
                                                          onClick={e => action(e, () => setSelection(o))}>
                    {converter(o)}
                </Button></div>)}
            </ModalBody>
        </Modal>}
        <Button className="mx-auto flex-grow-1" outline color="default" onClick={e => action(e, () => setPopover(!popover))}>{selection && converter(selection) || "Auswählen"}</Button>
    </span>
}
