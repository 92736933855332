import React, {useEffect, useState} from "react";
// react plugin used to create charts
// reactstrap components
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Collapse, Container, Form, Input, Row,} from "reactstrap";
// core components
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {theme} from "../variables/theme";
import {create, get, update} from "../variables/backend";
import CreateTripReport from "./trip/CreateTripReport";
import history from "../variables/history";
import {toDoubleDigit} from "../variables/toolkit";

const msToDate = (ms) => {
    const date = new Date(ms);
    return date.getFullYear() + "-" + toDoubleDigit(date.getMonth() + 1) + "-" + toDoubleDigit(date.getDate());
}

function CreateTripForm({openTrip, onSubmit, scout}) {
    const routes = useSelector(state => state?.routes);
    const [date, setDate] = useState(new Date().getTime());
    const [route, setRoute] = useState();
    const [open, setOpen] = useState(false);
    const [err, setErr] = useState();

    // init state with props
    useEffect(() => {
        if (openTrip) {
            setDate(openTrip.date);
            setRoute(openTrip.route);
        }
    }, [openTrip]);

    return <Card data-theme={theme}>
        <Form onSubmit={(evt) => {
            evt.preventDefault();
            if (onSubmit) {
                onSubmit(openTrip?.id, date, route?.id, (err) => setErr(err));
            }
        }}>
            <CardHeader>
                <h3 className="my-0">{openTrip && "Aktive" || "Neue"}&nbsp;Kontrollfahrt</h3>
            </CardHeader>
            <CardBody>
                <div className="d-block d-lg-flex container container-fluid">
                    <div className="flex-grow-1 my-3 mx-3">
                        Datum
                        <br/>
                        <Input type="date" onChange={evt => {
                            if (evt.target.value)
                                setDate(evt.target.valueAsNumber);
                            else
                                setDate(null);
                        }} value={msToDate(date)}/>
                    </div>
                    <div className="flex-grow-1 my-3 mx-3">
                        Scout
                        <br/>
                        <Input disabled value={scout.name}/>
                    </div>
                </div>
                <div className="mx-3">
                    <span className="mx-3 mb-0 pb-0">Route</span>
                    <div className="d-flex mt-0 pt-0">
                        <Button color="default" outline size="sm" className="flex-grow-1 mt-0 mx-3" onClick={e => {
                            e.preventDefault();
                            e.target.blur();
                            setOpen(!open);
                        }}>
                            <span>{route && route.name || "Auswählen"}</span>
                        </Button>
                    </div>
                    <Collapse isOpen={open}>
                        <div className="mx-3">{routes && routes.map((route, idx) =>
                            <Container key={idx} className="d-flex">
                                <Button color="default" outline size="sm" className="flex-grow-1"
                                        onClick={e => {
                                            e.preventDefault();
                                            e.target.blur();
                                            setOpen(!open);
                                            setRoute(route);
                                        }}
                                >
                                    <span>{route.name}</span>
                                </Button>
                            </Container>
                        )}</div>
                    </Collapse>
                </div>
                {err && <div>
                    <span className="text-danger font-italic">Kontrollfahrt kann nicht gestartet werden! ({err})</span>
                </div>}
            </CardBody>
            <CardFooter>
                <div className="d-flex mb-2">
                    <Button className="flex-grow-1" color="success" outline type="submit">
                        <span className="">{openTrip && "Fortsetzten" || "Starten"}</span>
                    </Button>
                </div>
            </CardFooter>
        </Form>
    </Card>
}

function Dashboard(props) {
    const [openTrips, setOpenTrips] = useState();
    const [oldTrips, setOldTrips] = useState();
    const toggleShowOld = () => {
        history.push("/trip")
    }

    useEffect(() => {
        get("/trips/", {state: 'open', limit: 1}).then(r => setOpenTrips(r)).catch(err => setOpenTrips(null));
    }, []);

    const onOpenTrip = (tripId, date, routeId, callback) => {
        if (!tripId) {
            if (date && routeId) {
                create("/trips/", {
                    date: date,
                    route: routeId,
                }).then(r => {
                    if (r && r?.trip)
                        history.push("/trip/" + r?.trip?.id)
                }).catch(err => {
                    console.log(err)
                    callback("Fehler beim Erstellen der Kontrollfahrt.");
                })
            } else {
                callback("Datum oder Route fehlt!");
            }
        } else {
            update("/trips/" + tripId, {
                date: date,
                route: routeId,
            }).then(r => {
                if (r && r?.trip)
                    history.push("/trip/" + r?.trip?.id)
                callback("Fehler beim Öffnen der Kontrollfahrt.");
            }).catch(err => {
                console.log(err)
                callback("Fehler beim Öffnen der Kontrollfahrt.");
            })
        }
    }

    return (
        <>
            <div className="content h-100 d-flex flex-column">
                <div>
                    <Row>
                        <Col>
                            <CreateTripForm
                                openTrip={openTrips && openTrips[0] || null}
                                scout={props.user}
                                onSubmit={onOpenTrip}
                            />
                        </Col>
                    </Row>
                </div>
                <div className="overflow-hidden">
                    <Button onClick={e => {
                        e.preventDefault();
                        e.target.blur();
                        toggleShowOld();
                    }}
                            color="default"
                            outline className="mx-auto w-100"
                    >Vergangene Fahrten anzeigen</Button>
                    <Collapse isOpen={!!oldTrips}>
                        {oldTrips?.length > 0 && <Row>
                            <Col>
                                {oldTrips?.map((trip, idx) => <Card data-theme={theme} key={idx}>
                                    <CardHeader>
                                        <span className="h4 text-secondary">Kontrollfahrt</span><br/>
                                        <span
                                            className="h3 text-primary">{new Date(trip.date).toLocaleDateString()}</span>
                                    </CardHeader>
                                    <CardBody>
                                        <div><span>{trip.route.name}</span></div>
                                        {trip.section && <div><span>{trip.section.name}</span></div>}
                                    </CardBody>
                                    <CardFooter>
                                        <NavLink to={"/trip/" + trip.id} className="pull-right">
                                            <FontAwesomeIcon icon="biking"/>&nbsp;&Ouml;ffnen
                                        </NavLink>
                                        <NavLink to={"/trip/" + trip.id + "/createReport"} className="pull-right mr-3">
                                            <FontAwesomeIcon icon="plus"/>&nbsp;Neue Meldung
                                        </NavLink>
                                    </CardFooter>
                                </Card>)}
                            </Col>
                        </Row>}
                    </Collapse>
                </div>
            </div>
        </>
    );
}

export default Dashboard;
