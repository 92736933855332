/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import {Col, Container, Row} from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";
import sbgld from "assets/img/logo_2021.png"
import eu from "assets/img/logo_veloregio.png"

class Footer extends React.Component {
    render() {
        return (
            <footer
                className={"footer" + (this.props.default ? " footer-default" : "")}
            >
                <Container fluid={this.props.fluid ? true : false}>
                    <Row>
                        <span className="text-center mx-auto"><big>Urschick-IT</big></span>
                        <div className="container-fluid d-flex mt-0 pt-0">
                            {/*<img src={sbgld} className="mx-auto"/><br/>*/}
                            <img src={eu} style={{height: "48px", marginRight: "auto", marginLeft: "auto"}}/>
                        </div>
                        <div className="credits mx-auto">
                            <div className="copyright">
                                &copy; {1900 + new Date().getYear()}, made by Andreas and Marc Urschick
                            </div>
                        </div>
                    </Row>
                </Container>
            </footer>
        );
    }
}

Footer.propTypes = {
    default: PropTypes.bool,
    fluid: PropTypes.bool,
};

export default Footer;
