/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {Link} from "react-router-dom";
import {
    Collapse,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Nav,
    Navbar,
    NavbarToggler,
    NavItem,
} from "reactstrap";
import NavLink from "reactstrap/lib/NavLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import history from "../../variables/history";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            dropdownOpen: false,
            userDropdownOpen: false,
            color: "transparent",
        };
        this.toggle = this.toggle.bind(this);
        this.dropdownToggle = this.dropdownToggle.bind(this);
        this.sidebarToggle = React.createRef();
    }

    toggle() {
        if (this.state.isOpen) {
            this.setState({
                color: "transparent",
            });
        } else {
            this.setState({
                color: "dark",
            });
        }
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    dropdownToggle(e) {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    userToggle(e) {
        this.setState({
            userDropdownOpen: !this.state.userDropdownOpen,
        });
    }

    openSidebar() {
        document.documentElement.classList.toggle("nav-open");
        this.sidebarToggle.current.classList.toggle("toggled");
    }

    // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
    updateColor() {
        if (window.innerWidth < 993 && this.state.isOpen) {
            this.setState({
                color: "dark",
            });
        } else {
            this.setState({
                color: "transparent",
            });
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateColor.bind(this));
    }

    componentDidUpdate(e) {
        if (
            window.innerWidth < 993 &&
            //e.history.location.pathname !== e.location.pathname &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
            this.sidebarToggle.current.classList.toggle("toggled");
        }
    }

    render() {
        return (
            // add or remove classes depending if we are on full-screen-maps page or not
            <Navbar
                color={this.state.color}
                expand="lg"
                className={
                    window.location.pathname.indexOf("full-screen-maps") !== -1
                        ? "navbar-absolute fixed-top"
                        : "navbar-absolute fixed-top " +
                        (this.state.color === "transparent" ? "navbar-transparent " : "")
                }
            >
                <Container fluid>
                    <div className="navbar-wrapper">
                        <div className="navbar-toggle">
                            <button
                                type="button"
                                ref={this.sidebarToggle}
                                className="navbar-toggler"
                                onClick={() => this.openSidebar()}
                            >
                                <span className="navbar-toggler-bar bar1"/>
                                <span className="navbar-toggler-bar bar2"/>
                                <span className="navbar-toggler-bar bar3"/>
                            </button>
                        </div>
                    </div>
                    <span className="mx-auto">
                            Radscout
                        </span>
                    <NavbarToggler onClick={this.toggle}>
                        <span className="navbar-toggler-bar navbar-kebab"/>
                        <span className="navbar-toggler-bar navbar-kebab"/>
                        <span className="navbar-toggler-bar navbar-kebab"/>
                    </NavbarToggler>
                    <Collapse
                        isOpen={this.state.isOpen}
                        navbar
                        className="justify-content-end"
                    >
                        <Nav navbar>
                            <NavItem>
                                <NavLink onClick={this.props.themeSwitch}>
                                    <i className="nc-icon nc-layout-11"/>
                                    <p>
                                        <span className="d-lg-none d-md-block">Theme</span>
                                    </p>
                                </NavLink>
                            </NavItem>
                            <Dropdown
                                nav
                                isOpen={this.state.userDropdownOpen}
                                toggle={(e) => this.userToggle(e)}
                            >
                                <DropdownToggle caret nav>
                                    <FontAwesomeIcon icon={"user"} className={"mr-2"}/>
                                    <p>
                                        <span className="d-block">{this.props.user.name}</span>
                                    </p>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem disabled onClick={() => history.push("/user/changePassword")}>Passwort
                                        ändern</DropdownItem>
                                    <DropdownItem onClick={() => history.push("/logout")}>Abmelden</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        );
    }
}

export default Header;
