import React, {useEffect, useState} from "react";
import de from "./de";

const TRIP_STATES = "trip.states";
const REPORT_STATES = "report.states";

const LANGUAGES = {
    DE: de,
};

class Strings {
    constructor(locale) {
        this.bundle = locale;
    }

    getString(key) {
        return this.bundle[key];
    }
}

let active, setActive;

function Localization(props) {
    [active, setActive] = useState();

    useEffect(() => {
        setActive(new Strings(LANGUAGES.DE));
    }, []);

    return <></>;
}

export default () => active;
export {
    TRIP_STATES,
    REPORT_STATES,
    LANGUAGES,
    Strings,
    Localization
}
