import {sha512} from "js-sha512";
import {create, get, remove} from "./backend";

class AuthState {
    constructor(message = null) {
        this.user = null;
        this.routes = [];
        this.message = message;
    }
}

class AuthenticationHandle {
    constructor() {
        this.subscriptions = [];
    }

    attemptLogin = async (credentials, hook) => {
        if (!hook) {
            hook = () => {
            };
        }

        let res;
        let model = new AuthState("Fehler beim Anmelden!");

        try {
            if (credentials) {
                const body = {
                    login: sha512(credentials.userRaw),
                    auth: sha512(credentials.passwordRaw || ""),
                    remember: credentials.remember,
                    check: false
                }
                res = await create("/authenticate/", body);
                //window.location.reload();
            } else {
                res = await get("/authenticate/");
            }

            // .then(response => {
            model.message = null;
            model.routes = res.routes;
            model.user = res;
        } catch (e) {
            console.log("error while logging in")
        }

        hook(model);
        this.notifyModelChanged(model);
        return model;
    }

    logout = (hook) => {
        remove("/authenticate/")
            .then(response => {
                if (response.success) {
                    const model = new AuthState();
                    hook && hook(model);
                    this.notifyModelChanged(model);
                }
            });
    }

    notifyModelChanged = (model) => {
        this.subscriptions.forEach(sub => sub && sub(model));
    }

    addUpdateSubscription = (calledMethod) => {
        this.subscriptions.push(calledMethod);
    }

    removeUpdateSubscription = (calledMethod) => {
        this.subscriptions = this.subscriptions.splice(this.subscriptions.indexOf(calledMethod), 1);
    }

    getRoutes = () => {
        return this.routes;
    }
}

const auth = new AuthenticationHandle();

export {auth};
